import React from 'react'
import Layout from '../components/layout'
import Modal from '../components/Modal'

import '../styles/benchmarking.scss'
import '../styles/modals.scss'

const BenchIndustrial = () => {
    return (
        <Layout pageTitle='Benchmarking Audit Report' nextBottomSlideBtn="Prioritization: Industry" nextBottomSlideBtnLink="/PriorityIndustry">
            <div className="wrapperContainer">
                <div className='benchContainer' id='root'>
                    <Modal 
                        alt = "CO2"
                        title = "Energy usage and emissions profile"
                        className = "CO2"
                        modal_desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        modal_image = '1'
                    />
                     <Modal 
                        alt = "ReuseableEnergy"
                        title = "Best and worst performing site in the portfolio"
                        className = "ReuseableEnergy"
                        modal_desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        modal_image = '2'
                    />
                    <Modal 
                        alt = "Buildings"
                        title = "Portfolio energy waste summary"
                        className = "Buildings"
                        modal_desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        modal_image = '3'
                    />
                    <Modal
                        alt = "Cloud"
                        title = "Portfolio average waste disaggregation"
                        className = "Cloud"
                        modal_desc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        modal_image = '4'
                    />
                </div>
            </div>
        </Layout>
    )
}
export default BenchIndustrial